import config from "./config";

export const url = {
  LOGIN_URL: "api/front/auth/login",
  SIGNUP_URL: "api/front/auth/register",
  LOGOUT_URL: "api/front/auth/logout",
  GET_USER_DETAILS_URL: "api/front/auth/me",
  RESET_PASSWORD_URL: "api/front/auth/reset",
  FORGOT_PASSWORD_URL: "api/front/auth/forget",
  GET_ALLCLIENTS_URL: "api/front/get-all-client",
  LOGIN_WITH_GOOGLE_URL: "api/front/login/google",
  CLIENT_GET_BASE64_URL : "api/front/imageBase64",
  CLIENT_GET_DOOR_STYLE : "api/front/get-door-style/",
  CLIENT_REQUEST_STEP_1_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_2_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_3_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_4_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_5_URL: "api/front/client-request",
  DESIGNER_ALLREQUEST_URL: "api/front/designer-open-request",
  SET_PASSWORD_URL: "api/front/client/login/invitation/verify",
  UPLOAD_COMMENT_MEDIA : "api/front/client-request/uploadFile",
  GET_HISTORY_DATA_URL: "api/front/request-history-management",
  CLIENT_REQUEST_DELETE_URL: "api/front/client-request/remove",
  CLIENT_REQUEST_UPDATE_STATUS_URL: "api/front/request-status",
  CLIENT_REQUEST_SEARCH_URL: "api/front/client-request-search",
  CLIENT_REQUEST_DETAIL_VIEW: "api/front/client-request-detail",
  CLIENT_REQUEST_COPY_URL: "api/front/client-request-replicate",
  CLIENT_REQUEST_STEP_3_GET_URL: "api/front/client-request/show",
  UPDATE_USER_PROFILE_INFORMATION : "api/front/my-profile-update",
  LOGIN_WITH_GOOGLE_CALLBACK_URL: "api/front/login/google/callback",
  DESIGNER_MYOPEN_REQUEST_URL: "api/front/designer-my-open-request",
  CLIENT_REQUEST_LISTING_DRAFT_URL: "api/front/client-request-draft",
  DESIGNER_UPDATE_WORKLOG_URL: "api/front/designer-request-work-log",
  CLIENT_REQUEST_COMMENT_RATING_URL: "api/front/client-request-rating",
  DESIGNER_UPDATE_ASSIGNEE_URL: "api/front/assign-request-to-designer",
  CLIENT_REQUEST_ADD_COMMENT_URL: "api/front/client-request-add-comment",
  DESIGNER_DELIVERED_REQUEST_URL: "api/front/designer-request-delivered",
  CLIENT_REQUEST_GET_DISCUSSION_URL: "api/front/client-request-discussion",
  CLIENT_REQUEST_KITCHEN_MEDIA_UPLOAD_URL: "api/front/client-media-upload",
  CLIENT_REQUEST_STEP_5_MEDIA_URL: "api/front/client-request/stepFiveMedia",
  DESIGNER_REQUEST_ADD_COMMENT_URL: "api/front/designer-request-add-comment",
  CLIENT_REQUEST_DETAIL_UPDATE_URL: "api/front/client-request-detail-update",
  CLIENT_REQUEST_LISTING_YOURQUEUE_URL: "api/front/client-request-open",
  CLIENT_REQUEST_DELETE_COMMENT_URL: "api/front/client-request-delete-comment",
  CLIENT_REQUEST_UPDATE_COMMENT_URL: "api/front/client-request-update-comment",
  DESIGNER_REQUEST_GET_DISCUSSION_URL: "api/front/designer-request-discussion",
  DESIGNER_REQUEST_UPDATE_STATUS_URL:"api/front/designer-request-status-change",
  DESIGNER_REQUEST_UPDATE_COMMENT_URL:"api/front/designer-request-update-comment",
  DESIGNER_REQUEST_DELETE_COMMENT_URL:"api/front/designer-request-delete-comment",
  CLIENT_REQUEST_LISTING_DESIGN_DELIVERED_URL:"api/front/client-request-delivered",
  CLIENT_REPORT_DETAILS: "api/front/client-report",
  CREATE_NEW_REQUEST: "api/front/client-request-create",
  CREATE_NEW_ADDRESS: "api/front/client-address",
  GET_COUNTRY_STATE: "api/front/client-address/create",
  GET_ADDRESS_LIST: "api/front/client-address",
  GET_ADDRESS: "api/front/client-address",
  CHANGE_DEFAULT_ADDRESS: "api/front/set-default-address",
  SUBMIT_PAYMENT_DATA: "api/front/subscription-payment",
  SAVED_CARDS: "api/front/saved-cards",
  SUBMIT_PAYMENT_DATA_SAVED_CARD: "api/front/saved-card-payment",
  UPDATE_ADDRESS: "api/front/client-address",
  CONTACTUS_URL: "api/front/contactus",
  CHANGE_PASSWORD:"api/front/change-password",
  ACCOUNT_INFO_UPDATE:"api/front/account-info-update",
  GET_ACCOUNT_INFO:"api/front/get-account-info",
  CLIENT_ADDRESS:"api/front/client-address",// ADD GETLIST DELETE EDIT UPDATE
  GET_COUNTRY_LIST:"api/front/client-address/create", 
  SET_DEFAULT_ADDRESS:"api/front/set-default-address",
  GET_SUBSCRIPTION:"api/front/get-subscription",
  VIEW_INVOICE:"api/front/view-invoice",
  GET_INVOICES:"api/front/get-invoices",
  GENERATE_INVOICE:"api/front/generate-invoice",
  CANCEL_SUBSCRIPTION:"api/front/cancel-subscription",
  DELETE_CARDS:"api/front/delete-card",
  GET_USERS_BY_COMPANY: "api/front/get-clients-by-company",
  REQUEST_PRIORITIZE_URL: "api/front/client-request/prioritise",
  REQUEST_DEPRIORITIZE_URL: "api/front/client-request/deprioritise",
  REQUEST_PRIORITIZE_LIST_URL:"api/front/client-request-prioritised",
  DESIGNER_REQUEST_PRIORITIZE_LIST_URL:"api/front/designer-prioritised-request",
  REQUEST_PRIORITIZE_SORT_URL:"api/front/client-request/prioritised/re-order",
  CLIENT_REQUEST_LISTING_WIP_URL: "api/front/client-request-wip",
  CLIENT_REQUEST_LISTING_NEEDS_INFO_URL:"api/front/client-request-needs-information",
  CLIENT_REQUEST_LISTING_CLOSED_URL:"api/front/client-request-closed",
  DESIGNER_WIP_REQUEST_URL:"api/front/designer-wip-request",
  DESIGNER_CLOSED_REQUEST_URL:"api/front/designer-closed-request",
  DESIGNER_NEEDS_INFO_REQUEST_URL:"api/front/designer-needs-information-request",
  DESIGNER_MY_WIP_REQUEST_URL: "api/front/designer-my-wip-request",
  DESIGNER_MY_NEEDS_INFO_REQUEST_URL:"api/front/designer-my-needs-information-request",
  DESIGNER_MY_DELIVERED_REQUEST_URL:"api/front/designer-my-delievered-request",
  DESIGNER_MY_CLOSED_REQUEST_URL:"api/front/designer-my-closed-request",
  GET_INDICATION_STATUS_URL:"api/front/has-request-updates-for-status-tabs",
  CLIENT_REQUEST_LISTING_ALL_URL:"api/front/client-request-all",
  CLIENT_REQUEST_LISTING_NEEDS_APPROVAL_URL: "api/front/client-request-needs-approval",
  DESIGNER_NEEDS_APPROVAL_REQUEST_URL: "api/front/designer-my-needs-approval-request",
  DESIGNER_CLIENT_NEEDS_APPROVAL_REQUEST_URL: "api/front/designer-request-needs-approval",
  UPLOAD_COMMENT_EDITOR_MEDIA: "api/front/request-upload-images",
  DESIGNER_MYALL_REQUEST_URL: 'api/front/designer-my-all-request',
  DESIGNER_ALL_REQUEST_URL: 'api/front/designer-all-request',
  GET_ACCOUNT_SPOC_INFO:"api/front/get-spoc-info",
  ROLL_BACK_REVISION_REQUEST_URL:"api/front/designer-rollback-revision-due-to-design-error-status",
  MARK_AS_SOLD_DEFAULT_DATA:"api/front/solds/create",
  MARK_AS_SOLD_FORM_DATA:"api/front/solds",
  MARK_AS_SOLD_DATA_UPDATE:"api/front/solds/",
  FEEDBACK_DATA:"api/front/feedbacks",
  FEEDBACK_QUESTIONS:"api/front/feedbacks/clients/questions",
  FEEDBACK_QUESTIONS_RATING:"api/front/feedbacks/clients/questions/responses",
};

export const reportUrls ={
  GET_CLIENT_PRODUCTIVITY_RATIO : 'api/front/report/client/productivity-ratio',
  DESIGNER_REPORT_DETAILS: "api/front/designer-report",
  GET_DESIGNER_PRODUCTIVITY_RATIO : 'api/front/report/designer/productivity-ratio',
  GET_CLIENT_TOTAL_REQUEST_SUBMITTED : 'api/front/report/client/total-request-submitted',
  GET_DESIGNER_TOTAL_REQUEST_SUBMITTED : 'api/front/report/designer/total-request-submitted',
}

const apiUrl = (name) => {
  return `${config.API_ENDPOINT}${url[name]}`;
};

export const getApiUrl = (path,params) => `${config.API_ENDPOINT}${path}${params}`

export default apiUrl;
