import React, { memo, useEffect } from "react";
import SmileyFeedback from "./SmileyFeedback";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions as feedbackActions } from "../../redux/reducers/FeedbackReducer";
import { useCookies } from "react-cookie";

const Feedback = () => {
  const [cookies] = useCookies(["clientAccessToken"]);
  const dispatch = useDispatch();
  const feedbackData = useSelector((state) => state.feedbackData);
  const {
    feedback,
    questions = [],
    feedbackUpdating,
    showCompletion,
  } = feedbackData;

  useEffect(() => {
    dispatch(feedbackActions.getFeedbackData(cookies.clientAccessToken));
  }, [cookies.clientAccessToken, dispatch]);

  useEffect(() => {
    if (feedback?.id) {
      dispatch(
        feedbackActions.getFeedbackQuestionsData(cookies.clientAccessToken)
      );
    }
  }, [feedback, cookies.clientAccessToken, dispatch]);

  const handleRating = (feedback_question_id, rating) => {
    dispatch(
      feedbackActions.updateFeedbackRating(
        { feedback_id: feedback?.id, feedback_question_id, response: rating },
        cookies.clientAccessToken
      )
    );
  };

  return (
    <div className="feedback-container">
      <div className="container">
        <SmileyFeedback
          handleRating={handleRating}
          feedback={feedback}
          questions={questions}
          feedbackUpdating={feedbackUpdating}
          showCompletion={showCompletion}
        />
      </div>
    </div>
  );
};

export default memo(Feedback);
