/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoaderComponent from "../Loader";

import "./index.scss";
import RequestListItem from "../RequestListItem";
import RequestListItemSort from "../RequestListItem/indexsortcard";

import MyRequestHeader from "../../components/MyRequestHeader";
import { actions as AuthActions } from '../../redux/reducers/AuthReducer';
import Tour from 'reactour'
import Feedback from "../feedback";

function MyRequestList({ requestStatusSection }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const {
    myRequestListingData,
    myRequestListingLoading,
    filters,
    start,
    length,
    copyAction,
    sortByFilter,
    sortDir,
  } = useSelector((state) => state.myRequest);
  const { indicationStatus } = useSelector((state) => state.auth);
  const [ratingCopyTimeSpentFlag, setRatingCopyTimeSpentFlag] = useState(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState(false);
  const [initialStatus, setInitialStatus] = useState(false);
  let intervalID;
  useEffect(() => {
    setInitialStatus(indicationStatus);
    intervalID = setInterval(() => {
      dispatch(AuthActions.getTabIndicationStatus(null, cookies.clientAccessToken));
    }, 30000);
    return () => {
      clearInterval(intervalID)
    }

  }, []);
  useEffect(() => {
    if (initialStatus && indicationStatus) {
      if (initialStatus[requestStatusSection] !== indicationStatus[requestStatusSection]) {
        getRequestList();
        setInitialStatus(indicationStatus);
      }
    }
  }, [indicationStatus])
  const { user } = useSelector((state) => state.auth);
  const [isOpenTour, setIsOpenTour] = useState(false);
  const steps = [
    {
      selector: '.request-drag',
      content: 'Drag the request from here to change the sequence order',
    },
    // ...
  ]
  useEffect(() => {
    dispatch(myRequestActions.hideSubNavbar(false));
    if (requestStatusSection === "closed") {
      setRatingCopyTimeSpentFlag(true);
      setHideDeleteIcon(true);
    } else if (requestStatusSection === "yourdrafts") {
      setRatingCopyTimeSpentFlag(false);
      setHideDeleteIcon(false);
    } else if (requestStatusSection === "prioritized") {
      setRatingCopyTimeSpentFlag(false);
      setHideDeleteIcon(false);
    } else {
      setRatingCopyTimeSpentFlag(false);
      setHideDeleteIcon(false);
    }
  }, [dispatch, requestStatusSection]);
  useEffect(() => {
    if (copyAction) {
      history.push("/app/client/submitrequest");
    }
  }, [copyAction]);
  useEffect(() => {
    if (myRequestListingData !== "" && myRequestListingData?.datatable && myRequestListingData?.datatable?.data.length !== 0
      && requestStatusSection === "prioritized" && user?.group_id !== 4 && user?.group_id !== 5 && user?.is_request_prioritisation_allowed == 1) {
      if (!localStorage.getItem('isTourMsgOpened')) {
        setIsOpenTour(true)
        localStorage.setItem("isTourMsgOpened", true);
      }
    }
  }, [myRequestListingData]);

  const viewRequest = (id) => {
    dispatch(myRequestActions.changeRequestId(id));
    // history.push("/app/client/myrequests/viewrequest/" + id);
  };
  const viewRequestUrl = (id) => {
    return ("/app/client/myrequests/viewrequest/" + id);
  };
  const deleteRequest = (id) => {
    if (window.confirm("Are you sure want to delete this request?")) {
      if (requestStatusSection === "yourdrafts") {
        dispatch(
          myRequestActions.deleteRequest(id, "draft", cookies.clientAccessToken)
        );
      } else if (requestStatusSection === "prioritized") {
        dispatch(
          myRequestActions.deleteRequest(id, "prioritized", cookies.clientAccessToken)
        );
      } else if (requestStatusSection === "all-requests") {
        dispatch(
          myRequestActions.deleteRequest(id, "all-requests", cookies.clientAccessToken)
        );
      } else {
        dispatch(
          myRequestActions.deleteRequest(id, "queue", cookies.clientAccessToken)
        );
      }
    }
  };
  const copyClientRequest = (id) => {
    if (id) {
      dispatch(
        myRequestActions.copyClientRequest(id, cookies.clientAccessToken)
      );
    }
  };
  const draftRequestClick = (id) => {
    dispatch(myRequestActions.changeCopyRequestId(id));
    dispatch(myRequestActions.changeCopyAction(true));
  };
  const requestPrioritize = (id, isDePrioritize) => {
    if (isDePrioritize) {
      dispatch(
        myRequestActions.requestDePrioritize(id, cookies.clientAccessToken)
      );
    } else {
      dispatch(
        myRequestActions.requestPrioritize(id, requestStatusSection, cookies.clientAccessToken)
      );
    }
  };
  const loadMoreRequest = () => {
    if (requestStatusSection === "needs-approval") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getNeesApprovalListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "yourdrafts") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getDraftListing(
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "prioritized") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getRequestListingPrioritize(
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "open-requests") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getOpenRequestListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "all-requests") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getAllRequestListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "wip") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getWipRequestListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "needs-information") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getNeedsInfoRequestListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "closed") {
      let nextRecord = start + length; //10;
      dispatch(myRequestActions.changeStartFrom(nextRecord));
      dispatch(
        myRequestActions.getClosedRequestListing(
          filters,
          sortByFilter,
          sortDir,
          nextRecord,
          length,
          cookies.clientAccessToken
        )
      );
    }
  };
  const closeTour = () => {
    setIsOpenTour(false);
    localStorage.setItem("isTourMsgOpened", true);
  };
  const getRequestList = () => {
    if (requestStatusSection === "needs-approval") {
      dispatch(
        myRequestActions.getNeesApprovalListing(
          filters,
          sortByFilter,
          sortDir,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "yourdrafts") {
      dispatch(
        myRequestActions.getDraftListing(0, length, cookies.clientAccessToken)
      );
    } else if (requestStatusSection === "open-requests") {
      dispatch(
        myRequestActions.getOpenRequestListing(
          filters,
          sortByFilter,
          sortDir,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "wip") {
      dispatch(
        myRequestActions.getWipRequestListing(
          filters,
          sortByFilter,
          sortDir,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "needs-information") {
      dispatch(
        myRequestActions.getNeedsInfoRequestListing(
          filters,
          sortByFilter,
          sortDir,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    } else if (requestStatusSection === "closed") {
      dispatch(
        myRequestActions.getClosedRequestListing(
          filters,
          sortByFilter,
          sortDir,
          0,
          length,
          cookies.clientAccessToken
        )
      );
    }
  }
  const handleFilterChange = (id, isSold = false) => {
    let tempFilter = filters;
    if (id) {
      tempFilter['request_status'] = id;
      tempFilter['is_sold'] = isSold ? 1 : 0
    } else {
      delete tempFilter.request_status;
      delete tempFilter.is_sold;
    }
    dispatch(myRequestActions.updateFilters(tempFilter));
    dispatch(myRequestActions.changeStartFrom(0));
    getRequestList();
  }
  return (
    <div>
      <Tour
        className="tour-msg"
        steps={steps}
        isOpen={isOpenTour}
        onRequestClose={() => { closeTour() }}
        highlightedMaskClassName="tour-highlighted-mask"
        showPrevNextButtons={true}
        showNavigation={false}
      />
      <LoaderComponent showhide={myRequestListingLoading} />
      {myRequestListingData['request_status'] && myRequestListingData['request_status'].length && requestStatusSection !== "all-requests" ? <div className="container cf sub-status" style={{ marginBottom: "40px", marginTop: "0" }}>
        {myRequestListingData['request_status'] && myRequestListingData['request_status'].length && requestStatusSection !== "all-requests" ?
          <button className={"btn" + (filters && filters['request_status'] === "" || !filters['request_status'] ? " active" : "")} onClick={() => { handleFilterChange("") }}>All</button> : null}
        {myRequestListingData['request_status'] && myRequestListingData['request_status'].map((data, index) => (
          <>
            <button
              key={"status" + data.id}
              className={"btn" + (filters?.request_status === data.id && !filters?.is_sold ? " active" : "")}
              onClick={() => { handleFilterChange(data.id) }}>
              {data.name === 'Approve' ? 'Approved' : data.name === 'Close' ? 'Closed' : data.name}
            </button>
            {data?.name === 'Approve' ?
              <button
                key={"status" + data.id}
                className={"btn" + (filters?.request_status === data.id && filters?.is_sold ? " active" : "")}
                onClick={() => { handleFilterChange(data.id, true) }}>
                SOLD
              </button>
              : null
            }
          </>
        ))}
      </div> : <div style={{ marginBottom: "25px" }}></div>}
      <MyRequestHeader
        requestStatusSection={requestStatusSection}
        myRequestListingData={myRequestListingData}
      />
      <Feedback />
      {requestStatusSection == "prioritized" ? <RequestListItemSort
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={myRequestListingData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={requestStatusSection === "yourdrafts" ? undefined : viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        deleteRequest={deleteRequest}
        copyClientRequest={copyClientRequest}
        requestStatusSection={requestStatusSection}
        draftRequestClick={draftRequestClick}
        requestPrioritize={requestPrioritize}
        isAllPriortise={false}
      /> : requestStatusSection == "all-requests" && user?.is_all_tab_prioritisation_enabled == 1 ? <RequestListItemSort
        ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
        data={myRequestListingData}
        loadMoreRequest={loadMoreRequest}
        viewRequest={viewRequest}
        viewRequestUrl={requestStatusSection === "yourdrafts" ? undefined : viewRequestUrl}
        hideDeleteIcon={hideDeleteIcon}
        deleteRequest={deleteRequest}
        copyClientRequest={copyClientRequest}
        requestStatusSection={requestStatusSection}
        draftRequestClick={draftRequestClick}
        requestPrioritize={requestPrioritize}
        isAllPriortise={false}
      /> :
        <RequestListItem
          ratingCopyTimeSpentFlag={ratingCopyTimeSpentFlag}
          data={myRequestListingData}
          loadMoreRequest={loadMoreRequest}
          viewRequest={viewRequest}
          viewRequestUrl={requestStatusSection === "yourdrafts" ? undefined : viewRequestUrl}
          hideDeleteIcon={hideDeleteIcon}
          deleteRequest={deleteRequest}
          copyClientRequest={copyClientRequest}
          requestStatusSection={requestStatusSection}
          draftRequestClick={draftRequestClick}
          requestPrioritize={requestPrioritize}
          isAllPriortise={false}
        />}
    </div>
  );
}

export default MyRequestList;
