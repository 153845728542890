import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import ShortUniqueId from "short-unique-id";
import { getApiToken, postApiToken } from "../apis";
import apiUrl from "../../config/urls";
import { actions as generalActions } from "./GeneralReducer";
import queryString from "query-string";
const uid = new ShortUniqueId();

export const actionTypes = {
  SET_FEEDBACK_LOADING: "SET_FEEDBACK_LOADING",
  GET_FEEDBACK_DATA: "GET_FEEDBACK_DATA",
  SET_FEEDBACK_DATA: "SET_FEEDBACK_DATA",
  SET_FEEDBACK_QUESTION_LOADING: "SET_FEEDBACK_QUESTION_LOADING",
  GET_FEEDBACK_QUESTIONS: "GET_FEEDBACK_QUESTIONS",
  SET_FEEDBACK_QUESTIONS: "SET_FEEDBACK_QUESTIONS",
  SET_FEEDBACK_UPDATING: "SET_FEEDBACK_UPDATING",
  UPDATE_FEEDBACK_RATING: "UPDATE_FEEDBACK_RATING",
  SHOW_COMPLETION_MESSAGE: "SHOW_COMPLETION_MESSAGE",
};

const initialGeneralState = {
  feedbackLoading: false,
  feedback: {},
  feedbackQuestionLoading: false,
  questions: [],
  feedbackUpdating: false,
  showCompletion: false,
};

export const reducer = (state = initialGeneralState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEEDBACK_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_DATA: {
      const { feedback } = action.payload;
      return {
        ...state,
        feedback: feedback,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackQuestionLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTIONS: {
      const { questions } = action.payload;
      return {
        ...state,
        questions: questions,
      };
    }
    case actionTypes.SET_FEEDBACK_UPDATING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackUpdating: flag,
      };
    }
    case actionTypes.SHOW_COMPLETION_MESSAGE: {
      const { flag } = action.payload;
      return {
        ...state,
        showCompletion: flag,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  setFeedbackLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_LOADING,
    payload: { flag },
  }),
  getFeedbackData: (clientAccessToken) => ({
    type: actionTypes.GET_FEEDBACK_DATA,
    payload: { clientAccessToken },
  }),
  setFeedbackData: (feedback) => ({
    type: actionTypes.SET_FEEDBACK_DATA,
    payload: { feedback },
  }),
  setFeedbackQuestionLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_QUESTION_LOADING,
    payload: { flag },
  }),
  getFeedbackQuestionsData: (clientAccessToken, showCompletion = false) => ({
    type: actionTypes.GET_FEEDBACK_QUESTIONS,
    payload: { clientAccessToken, showCompletion },
  }),
  setFeedbackQuestionsData: (questions) => ({
    type: actionTypes.SET_FEEDBACK_QUESTIONS,
    payload: { questions },
  }),
  setFeedbackUpdating: (flag) => ({
    type: actionTypes.SET_FEEDBACK_UPDATING,
    payload: { flag },
  }),
  updateFeedbackRating: (feedbackData, clientAccessToken) => ({
    type: actionTypes.UPDATE_FEEDBACK_RATING,
    payload: { feedbackData, clientAccessToken },
  }),
  showCompletionMessage: (flag) => ({
    type: actionTypes.SHOW_COMPLETION_MESSAGE,
    payload: { flag },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_FEEDBACK_DATA, getFeedbackSaga);
  yield takeLatest(
    actionTypes.GET_FEEDBACK_QUESTIONS,
    getFeedbackQuestionsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_FEEDBACK_RATING,
    updateFeedbackRatingSaga
  );
}

function* getFeedbackSaga(action) {
  const { clientAccessToken } = action.payload;
  yield all([put(actions.setFeedbackLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("FEEDBACK_DATA"),
      null,
      clientAccessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData?.data?.["feedback"]) {
        yield all([
          put(actions.setFeedbackLoading(false)),
          put(actions.setFeedbackData(responseData.data["feedback"])),
        ]);
      } else {
        yield all([put(actions.setFeedbackLoading(false))]);
      }
    } else if (response?.status === 422) {
      const responseData = yield call([response, response.json]);
      if (responseData?.message) {
        yield all([
          put(actions.setFeedbackLoading(false)),
          put(
            generalActions.addToast(
              "Get Feedback Data",
              responseData?.message,
              "danger",
              uid()
            )
          ),
        ]);
      } else {
        yield all([
          put(actions.setFeedbackLoading(false)),
          put(
            generalActions.addToast(
              "Get Feedback Data",
              "You do not have permission to perform this action!",
              "danger",
              uid()
            )
          ),
        ]);
      }
    }
  } catch (error) {
    yield all([
      put(actions.setFeedbackLoading(false)),
      put(
        generalActions.addToast(
          "Get Feedback Data",
          "Something went wrong!",
          "danger",
          uid()
        )
      ),
    ]);
  }
}

function* handleFeedbackQuestionsResponse(response, showCompletion) {
  if (response.status === 200) {
    const responseData = yield call([response, response.json]);
    if (responseData?.data?.["feedback-questions"]) {
      const feedbackQuestions = responseData.data["feedback-questions"];
      const questions = Array.isArray(feedbackQuestions)
        ? feedbackQuestions
        : [feedbackQuestions[1]];

      yield all([
        put(actions.setFeedbackQuestionLoading(false)),
        put(actions.setFeedbackQuestionsData(questions)),
      ]);
    } else {
      yield all([put(actions.setFeedbackQuestionLoading(false))]);
      if (showCompletion) {
        yield put(actions.showCompletionMessage(true));
        yield delay(5000);
        yield put(actions.showCompletionMessage(false));
      }
    }
  } else if (response?.status === 422) {
    const responseData = yield call([response, response.json]);
    if (responseData?.message) {
      if (showCompletion) {
        yield put(actions.showCompletionMessage(true));
        yield delay(5000);
        yield put(actions.showCompletionMessage(false));
      }
      yield all([
        put(actions.setFeedbackQuestionLoading(false)),
        put(actions.setFeedbackQuestionsData([])),
      ]);
    } else {
      yield all([
        put(actions.setFeedbackQuestionLoading(false)),
        put(
          generalActions.addToast(
            "Get Feedback Data",
            "You do not have permission to perform this action!",
            "danger",
            uid()
          )
        ),
      ]);
    }
  }
}

function* handleFeedbackQuestionsError() {
  yield all([
    put(actions.setFeedbackQuestionLoading(false)),
    put(
      generalActions.addToast(
        "Get Feedback Data",
        "Something went wrong!",
        "danger",
        uid()
      )
    ),
  ]);
}

function* getFeedbackQuestionsSaga(action) {
  const { clientAccessToken, showCompletion = false } = action.payload;
  yield all([put(actions.setFeedbackQuestionLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("FEEDBACK_QUESTIONS"),
      null,
      clientAccessToken
    );
    yield call(handleFeedbackQuestionsResponse, response, showCompletion);
  } catch (error) {
    console.log(error);
    yield call(handleFeedbackQuestionsError);
  }
}

function* updateFeedbackRatingSaga(action) {
  let { feedbackData, clientAccessToken } = action.payload;
  yield all([put(actions.setFeedbackUpdating(true))]);

  try {
    const response = yield call(
      postApiToken,
      `${apiUrl("FEEDBACK_QUESTIONS_RATING")}`,
      clientAccessToken,
      queryString.stringify(feedbackData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        const { questions } = yield select((state) => state.feedbackData);
        yield put(actions.getFeedbackQuestionsData(clientAccessToken));
        if (questions?.length === 1) {
          yield put(actions.showCompletionMessage(true));
          yield delay(5000);
          yield put(actions.showCompletionMessage(false));
        } else {
          yield delay(500);
          yield put(actions.setFeedbackUpdating(false));
        }
      } else {
        yield all([put(actions.setFeedbackUpdating(false))]);
      }
    } else if (response.status === 422) {
      let errorData = yield call([response, response.json]);
      yield put(actions.getFeedbackQuestionsData(clientAccessToken, true));
      yield all([
        put(actions.setFeedbackUpdating(false)),
        put(
          generalActions.addToast(
            "Update Feedback Response",
            errorData.message,
            "success",
            uid()
          )
        ),
      ]);
      yield put(actions.getFeedbackQuestionsData(clientAccessToken));
      yield delay(5000);
      yield put(actions.showCompletionMessage(false));
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        // yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackUpdating(false))]);
        yield put(
          generalActions.addToast(
            "Update Feedback Response",
            "You do not have permission to perform this action!",
            "danger",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackUpdating(false))]);
      yield put(
        generalActions.addToast(
          "Update Feedback Response",
          "Something went wrong!",
          "danger",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackUpdating(false))]);
    yield put(
      generalActions.addToast(
        "Update Feedback Response",
        "Something went wrong!",
        "danger",
        uid()
      )
    );
  }
}
