import { all } from "redux-saga/effects";
import { combineReducers } from 'redux';
import * as auth from './AuthReducer';
import * as general from './GeneralReducer';
import * as manageClients from './ManageClientReducer';
import * as home from "./HomeReducer";
import * as myAccountReducer from "./MyAccountReducer";
import * as clientRequest from "./NewRequestReducer";
import * as myRequest from "./MyRequestReducer";
import * as designerRequest from "./DesignerRequestReducer";
import clientReportsReducer from './clientReports.reducer'
import designerReportsReducer from './designerReports.reducer'
import clientReportsSaga from '../sagas/clientReports.sagas'
import designerReports from '../sagas/designerReports.sagas'
import * as feedbackReducer from "./FeedbackReducer";

export default combineReducers({
  auth: auth.reducer,
  general: general.reducer,
  manageClients: manageClients.reducer,
  clientRequest: clientRequest.reducer,
  home: home.reducer,
  myAccountReducer: myAccountReducer.reducer,
  myRequest: myRequest.reducer,
  designerRequest: designerRequest.reducer,
  clientReportsReducer,
  designerReportsReducer,
  feedbackData: feedbackReducer.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(), 
    myRequest.saga(), 
    manageClients.saga(), 
    home.saga(), 
    myAccountReducer.saga(),
    clientRequest.saga(), 
    designerRequest.saga(),
    clientReportsSaga(),
    designerReports(),
    feedbackReducer.saga(),
  ]);
}

