import React, { memo } from "react";
import PropTypes from "prop-types";

const CompletionMessage = ({ feedback }) => {
  return (
    <div className="item feedback-item">
      <div className="feedback-item__header">
        <h4>{feedback?.title}</h4>
      </div>
      <div className="feedback-item__body">
        <div className="feedback-item__body--question">
          <h3>{feedback.completion_message} 🎉</h3>
          <p>{`Thank you! We'll share it with our team and use it to improve our customer service.`}</p>
        </div>
        <div className="feedback-item__body--rating"></div>
      </div>
    </div>
  );
};
CompletionMessage.propTypes = {
  feedback: PropTypes.object,
};

export default memo(CompletionMessage);
